import generateAddendum from '@/mixins/final-note/addendums/Addendums'

export default {
  mixins: [generateAddendum],
  methods: {
    generateFinalNote() {
      let finalNote = 'ENCOUNTER NOTES:<br>'
      finalNote += this.$custom.textAreaHtml(this.encounter.notes)
      finalNote += this.generateFinalNoteAddendum()
      this.final_note = this.$custom.clean(finalNote, 'Psychiatry', false)
    },
  },
}
